const SET_ERROR = 'SET_ERROR';

type Error = {
  code: ?string,
  description: ?string,
  priority: ?string,
};
type Action = {
  type: typeof SET_ERROR,
  error: Error,
};

export type State = Error;

const initialState: State = {
  code: null,
  description: null,
  priority: null,
};

export const reducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case SET_ERROR: {
      return action.error;
    }

    default: {
      return state;
    }
  }
};

export const setErrorAction = (error: Error) => ({
  type: SET_ERROR,
  error,
});

export default reducer;
