const SET_USER_DATA = 'SET_USER_DATA';
const LOAD_USER_DATA = 'LOAD_USER_DATA';
const UPDATE_USER_BALANCE = 'UPDATE_USER_BALANCE';
const CLEAR_USER_DATA = 'CLEAR_USER_DATA';

export type Action =
  | { type: typeof SET_USER_DATA, payload: Object }
  | { type: typeof LOAD_USER_DATA, payload: Object }
  | { type: typeof UPDATE_USER_BALANCE, balance: number }
  | { type: typeof CLEAR_USER_DATA };

export type State = {
  ID: number,
  balance: number,
  RealMoneyBalance: number,
  NickName: string,
  balanceUpdateTimestamp: string,
  balanceSequence: string,
  lastWin: number,
  totalWin: number,
  sessionBetsAmount: number,
  totalWinIsOpen: boolean,
};

const initialState: State = {
  ID: 0,
  balance: 0,
  RealMoneyBalance: 0,
  NickName: '',
  balanceUpdateTimestamp: '',
  balanceSequence: '',
  lastWin: 0,
  totalWin: 0,
  sessionBetsAmount: 0,
  totalWinIsOpen: false,
};

export default function reducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case SET_USER_DATA: {
      return { ...state, ...action.payload };
    }
    case LOAD_USER_DATA: {
      return { ...state, ...action.payload };
    }
    case UPDATE_USER_BALANCE: {
      return { ...state, balance: action.balance || 0 };
    }
    case CLEAR_USER_DATA: {
      return { ...state, sessionBetsAmount: 0, lastWin: 0 };
    }

    default: {
      return state;
    }
  }
}

export const setUserData = (payload: Object): Action => ({
  type: SET_USER_DATA,
  payload,
});

export const loadUserData = (payload: Object): Action => ({
  type: LOAD_USER_DATA,
  payload,
});

export const updateUserBalance = (balance: number): Action => ({
  type: UPDATE_USER_BALANCE,
  balance,
});

export const clearUserData = (): Action => ({
  type: CLEAR_USER_DATA,
});
