import React from 'react';
import { Spin } from 'antd';

const Loading = ({ text }) => {
  return (
    <Spin tip="Loading" size="large">
      {text}
    </Spin>
  );
};

export default Loading;
