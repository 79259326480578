import React from 'react';

import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';

import { Table, Space } from 'antd';

const columns = [
  // {
  //   title: 'ID',
  //   dataIndex: 'id',
  //   responsive: ['lg'],
  // },
  {
    title: 'Name',
    dataIndex: 'name',
    render: (text, record) => <Link to={`/users/edit/${record.id}`}>{text}</Link>,
    sorter: (a, b) => a.name.length - b.name.length,
    sortDirections: ['descend'],
  },

  {
    title: 'Role',
    dataIndex: 'role',
    filters: [
      {
        text: 'Member',
        value: 'member',
      },
      {
        text: 'Admin',
        value: 'admin',
      },
      {
        text: 'Staff',
        value: 'staff',
      },
    ],
    // specify the condition of filtering result
    // here is that finding the name started with `value`
    onFilter: (value, record) => record.role.indexOf(value) === 0,
  },

  {
    title: 'email',
    dataIndex: 'email',
    sorter: (a, b) => a.email.length - b.email.length,
    sortDirections: ['descend', 'ascend'],
    responsive: ['md'],
  },
  {
    title: 'Action',
    key: 'action',
    render: ({ id }) => (
      <Space size="middle">
        <Link to={`/users/edit/${id}`}>
          <EditOutlined style={{ fontSize: '1.2em' }} />
        </Link>
      </Space>
    ),
  },
  // {
  //   title: 'Active',
  //   dataIndex: 'isActive',
  //   filters: [
  //     {
  //       text: 'Active',
  //       value: true,
  //     },
  //     {
  //       text: 'In Active',
  //       value: false,
  //     },
  //   ],
  //   // specify the condition of filtering result
  //   // here is that finding the name started with `value`
  //   onFilter: (value, record) => record.isActive === value,
  //   render: (bool) => <Switch checked={bool} />,
  // },
];

// function onChange(pagination, filters, sorter, extra) {
//   console.log('params', pagination, filters, sorter, extra);
// }

function UserTable({ data, options, onChangeTable, onDeleteQrcode }) {
  return (
    <Table
      tableLayout="auto"
      className="clearfix"
      columns={columns}
      dataSource={data}
      style={{ clear: 'both' }}
      rowKey="id"
      pagination={{
        current: options.page,
        pageSize: options.limit,
        total: options.totalResults,
      }}
      onChange={onChangeTable}
    />
  );
}

export default UserTable;
