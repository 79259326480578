import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from 'src/context/auth';

const RequireAuth = ({ children }) => {
  const location = useLocation();
  const { isAuth } = useAuth();
  return isAuth ? children : <Navigate to="/" replace state={{ path: location.pathname }} />;
};

export default RequireAuth;
