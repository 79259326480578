import React from 'react';
import { DeleteOutlined, EditOutlined, CheckCircleOutlined, ClockCircleOutlined, CopyTwoTone } from '@ant-design/icons';
import dayjs from 'dayjs';
import { Table, Space, Popconfirm, Tag } from 'antd';
import { Link } from 'react-router-dom';

const tags = {
  enabled: {
    color: 'success',
    icon: <CheckCircleOutlined />,
  },
  disabled: {
    color: 'default',
    icon: <ClockCircleOutlined />,
  },
  deleted: {
    color: 'error',
    icon: <CheckCircleOutlined />,
  },
};

function DiscountTable({ data, options, onChangeTable, onDeleteDiscount }) {
  const columns = [
    {
      title: 'Unique id',
      dataIndex: 'shortId',
      key: 'shortId',
      render: (name, cell) => {
        const handelCopy = () => {
          navigator.clipboard.writeText(name);
        };

        return (
          <>
            <CopyTwoTone onClick={handelCopy} style={{ marginRight: '5px', verticalAlign: 'middle' }} />
            <Link to={`/discount/edit/${cell.id}`}>{name}</Link>
          </>
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      sortOrder: options.sorter?.field === 'name' && options.sorter?.order,
    },
    {
      title: 'Type Product',
      dataIndex: 'typeProduct',
      key: 'typeProduct',
      sorter: true,
      sortOrder: options.sorter?.field === 'typeProduct' && options.sorter?.order,
      render: (items) => items.join(', '),
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      key: 'discount',
      sorter: true,
      sortOrder: options.sorter?.field === 'discount' && options.sorter?.order,
      render: (discount) => `${discount} %`,
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      sortOrder: options.sorter?.field === 'createdAt' && options.sorter?.order,
      render: (createdAt) => `${dayjs(createdAt).format('DD MMM YYYY HH:mm')}`,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      sortOrder: options.sorter?.field === 'status' && options.sorter?.order,
      render: (status) => {
        return (
          <Tag icon={tags[status]['icon']} color={tags[status]['color']}>
            {status}
          </Tag>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: ({ id }) => (
        <Space size="middle">
          <Link to={`/discount/edit/${id}`}>
            <EditOutlined style={{ fontSize: '1.2em' }} />
          </Link>
          <Popconfirm title="Sure to delete?" onConfirm={() => onDeleteDiscount(id)}>
            <DeleteOutlined style={{ fontSize: '1.2em', color: '#d12c03' }} />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  return (
    <Table
      tableLayout="auto"
      className="clearfix"
      columns={columns}
      dataSource={data}
      style={{ clear: 'both' }}
      rowKey="id"
      pagination={{
        current: options.page,
        pageSize: options.limit,
        total: options.totalResults,
      }}
      onChange={onChangeTable}
    />
  );
}

export default DiscountTable;
