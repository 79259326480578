import React from 'react';
import { Typography } from 'antd';
import defaultHOCs from 'src/hocs/defaultHOCs';

const { Title } = Typography;

function Dashboard() {
  return (
    <div className="container">
      <Title>Dashboard</Title>
    </div>
  );
}

export default defaultHOCs(Dashboard);
