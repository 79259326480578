import React from 'react';
import { Row, Col } from 'antd';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { QrcodeOutlined, LinkOutlined } from '@ant-design/icons';
import defaultHOCs from 'src/hocs/defaultHOCs';

function AddQrcodes() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const onFinish = async (values) => {
    try {
      setIsLoading(true);
      await axios.post('/v1/qrcodes/insert-many', values);
      navigate('/qrcode/list');
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Form
        name="qrcode_generation_form"
        className="qrcode-generation-form"
        onFinish={onFinish}
        layout="vertical"
        size="large"
        style={{ clear: 'both' }}
        initialValues={{
          count: 1,
        }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={24}>
            <Form.Item
              name="baseUrl"
              label="Qrcode baseUrl without unique id"
              rules={[
                {
                  required: true,
                  message: 'Qrcode baseUrl',
                },
              ]}
            >
              <Input
                prefix={<LinkOutlined className="site-form-item-icon" />}
                placeholder="Qrcode baseUrl without unique id"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="count"
              label="Count generation qrcodes"
              rules={[
                {
                  required: true,
                  message: 'Invalid count!',
                },
              ]}
            >
              <Input
                type="number"
                min={1}
                max={1000}
                prefix={<QrcodeOutlined className="site-form-item-icon" />}
                placeholder="Count generation qrcode"
              />
            </Form.Item>
          </Col>
        </Row>
        <Button type="primary" loading={isLoading} htmlType="submit" size="middle" disabled={isLoading}>
          Save
        </Button>
        <Button type="info" className="qrcode-form-button" size="middle">
          <Link to="/qrcode/list">Back</Link>
        </Button>
      </Form>
    </>
  );
}

export default defaultHOCs(AddQrcodes);
