import React, { useEffect } from 'react';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Form, Input, Button } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from 'src/context/auth';

const LoginForm = ({ history }) => {
  const { login, isAuth } = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();

  const onFinish = (values) => {
    login(values).then(() => {
      navigate(state?.path || '/dashboard');
    });
  };

  useEffect(() => {
    if (isAuth) {
      navigate(state?.path || '/dashboard');
    }
  }, [isAuth, navigate, state]);

  return (
    <div className="test">
      <div className="container ">
        <div>
          <div className="row justify-content-center align-items-center min-vh-100">
            <div className="col-md-4 align-self-center text-center">
              <h2>Welcome Back!</h2>
              <p>Input your login details to continue</p>
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                size="large"
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your email!',
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Email"
                    className="rounded-pill"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Password!',
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                    className="rounded-pill"
                  />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" className="login-form-button" loading={false}>
                    Log in
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
