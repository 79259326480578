import React, { useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { Form, Input, Button, Upload, Select, Switch, Radio, notification } from 'antd';
import { useAsync } from 'react-use';
import { useNavigate } from 'react-router-dom';

import {
  UserOutlined,
  UploadOutlined,
  PlusOutlined,
  PhoneOutlined,
  MailOutlined,
  GlobalOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import Loading from 'src/components/Loading';
import defaultHOCs from 'src/hocs/defaultHOCs';
import { useSimpleReducer } from 'src/hooks/state';
import styles from './styles.module.scss';
// import { useQrcode } from 'src/queries';

const { TextArea } = Input;
const { Option } = Select;

const mappingfileList = (files) =>
  files?.map((media) => ({
    uid: media._id,
    name: media.file,
    status: 'done',
    url: `./media/${media.file}`,
  }));

const mappingFormData = (data) => ({ id: data._id, ...data });

const defaultValue = {
  msg: '',
  sign: '',
  shortId: '',
  email: '',
  author: '',
  authorStatus: 'show',
  status: 'pending',
  typeMessage: 'letter',
  ads: false,
};

const uploadButton = (
  <div>
    <PlusOutlined />
    <div style={{ marginTop: 8 }} />
  </div>
);

function EditQrcode(props) {
  const { id } = useParams();
  const navigate = useNavigate();

  const [initialLoad, setInitialLoad] = useState(false);

  const [state, updateState] = useSimpleReducer({
    images: [],
    audio: [],
  });

  // const { isLoading, data } = useQrcode(id);
  const data = useAsync(async () => {
    const result = await axios.get(`/v1/qrcodes/${id}`);
    const qr = result.data;
    updateState({ images: mappingfileList(qr.img), audio: mappingfileList(qr.audio) });
    return result.data;
  }, [id]);

  const onFinish = async (values) => {
    try {
      setInitialLoad(true);
      const { id, ...rest } = values;
      await axios.patch(`/v1/qrcodes/${id}`, rest);
      navigate(-1);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error?.response?.data?.message || error?.message,
        duration: 8,
      });
    } finally {
      setInitialLoad(false);
    }
  };

  const handleOnSuccessUpload = (qr) => {
    updateState({ images: mappingfileList(qr.img), audio: mappingfileList(qr.audio) });
  };

  const handleRemove = async (file) => {
    const result = await axios.post(`/v1/qrcodes/delete-media/${id}`, { ids: [file.uid] });
    const qr = result.data;
    updateState({ images: mappingfileList(qr.img), audio: mappingfileList(qr.audio) });
  };

  return (
    <>
      {data.loading ? (
        <Loading text={'Getting qrcode from DB! Hold on gee...'} />
      ) : (
        <Form
          name="qrcode_edit_form"
          className="qrcode-edit-form"
          onFinish={onFinish}
          layout="vertical"
          size="middle"
          style={{ clear: 'both' }}
          initialValues={{
            ...mappingFormData(data.value),
          }}
          defaultValue={{
            ...defaultValue,
          }}
        >
          <Form.Item
            hidden
            name="id"
            rules={[
              {
                required: true,
                message: 'ID required',
              },
            ]}
          >
            <Input type="hidden" value={data?.value?._id} />
          </Form.Item>
          <Form.Item
            name="shortId"
            label="UniqID/URL"
            rules={[
              {
                required: true,
                message: 'UniqID/URL is required',
                min: 3,
                max: 100,
              },
            ]}
          >
            <Input prefix={<GlobalOutlined className="site-form-item-icon" />} placeholder="UniqID/URL" />
          </Form.Item>
          <Form.Item name="msg" label="Message">
            <TextArea showCount rows={4} placeholder="maxLength is 1000" maxLength={1000} />
          </Form.Item>

          <Form.Item name="author" label="Author">
            <Input
              addonAfter={
                <Form.Item name="authorStatus" noStyle>
                  <Select style={{ width: 80 }}>
                    <Option value="hide">hide</Option>
                    <Option value="show">show</Option>
                  </Select>
                </Form.Item>
              }
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Author"
            />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Phone"
            rules={[
              {
                pattern: '^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{3,6}$',
                message: 'Phone number is required',
              },
            ]}
          >
            <Input prefix={<PhoneOutlined className="site-form-item-icon" />} placeholder="Phone" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                type: 'email',
              },
            ]}
          >
            <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />
          </Form.Item>
          <Form.Item label="Images">
            <Upload
              multiple
              accept="image/*"
              name="media"
              method="PATCH"
              action={`/v1/qrcodes/${id}`}
              listType="picture-card"
              fileList={state.images}
              onSuccess={handleOnSuccessUpload}
              onRemove={handleRemove}
            >
              {state.images.length >= 100 ? null : uploadButton}
            </Upload>
          </Form.Item>
          <Form.Item label="Audio">
            <Upload
              accept="audio/*"
              maxCount={1}
              name="media"
              method="PATCH"
              action={`/v1/qrcodes/${id}`}
              listType="picture"
              fileList={state.audio}
              onSuccess={handleOnSuccessUpload}
              onRemove={handleRemove}
              className="upload-list-inline"
              itemRender={(originNode, file, _, actions) => {
                return (
                  <div className={styles.itemRender}>
                    <audio controls src={file.url}></audio>
                    <DeleteOutlined onClick={actions.remove} />
                  </div>
                );
              }}
            >
              {state.audio.length < 1 && <Button icon={<UploadOutlined />} />}
            </Upload>
          </Form.Item>
          <Form.Item name="status" label="Status">
            <Select style={{ width: 150 }}>
              <Option value="default">Default</Option>
              <Option value="completed">Completed</Option>
              <Option value="deleted">Deleted</Option>
              <Option value="pending">Pending</Option>
            </Select>
          </Form.Item>
          <Form.Item name="typeMessage" label="Type Message">
            <Radio.Group initialValues="letter">
              <Radio value="letter">Letter</Radio>
              <Radio value="talisman">Talisman</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item name="sendEmail" valuePropName="checked" label="Send email only for status pending/completed">
            <Switch />
          </Form.Item>

          <Form.Item name="ads" valuePropName="checked" label="Accept Ads">
            <Switch />
          </Form.Item>
          <Button type="primary" loading={initialLoad} htmlType="submit" size="middle" disabled={initialLoad}>
            Save
          </Button>
          <Button type="info" className="qrcode-form-button" size="middle">
            <Link to="/qrcode/list">Back</Link>
          </Button>
        </Form>
      )}
    </>
  );
}

export default defaultHOCs(EditQrcode);
