import React from 'react';
import { Layout, Menu, Typography } from 'antd';
import { HomeOutlined, SettingOutlined, QrcodeOutlined, PercentageOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import SidebarStyled from './SidebarStyled';

const { Title } = Typography;
const { Sider } = Layout;

function Sidebar({ collapsed, index, loggedInUserId }) {
  const menuItems = [
    {
      key: 'dashboard',
      icon: <HomeOutlined />,
      label: (
        <Link className="text-white" to="/dashboard">
          Dashboard
        </Link>
      ),
    },
    {
      key: 'Qrcode',
      icon: <QrcodeOutlined />,
      label: (
        <Link className="text-white" to="/qrcode/list">
          Qrcode
        </Link>
      ),
    },
    {
      key: 'Discounts',
      icon: <PercentageOutlined />,
      label: (
        <Link className="text-white" to="/discount/list">
          Discounts
        </Link>
      ),
    },
    {
      key: 'Users',
      icon: <SettingOutlined />,
      label: <Link to={`/users`}>Users</Link>,
    },
  ];
  return (
    <SidebarStyled collapsed={collapsed}>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        // style={{
        //   overflow: 'auto',
        //   height: '100vh',
        //   position: 'fixed',
        //   left: 0
        // }}
      >
        <div className="logo">
          <Title level={2}>Jewel</Title>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          className="sidebar-items"
          defaultSelectedKeys={[index || 'dashboard']}
          items={menuItems}
        />
      </Sider>
    </SidebarStyled>
  );
}

export default Sidebar;
