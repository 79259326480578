import React from 'react';
import { Layout } from 'antd';

const { Footer } = Layout;

function CustomFooter() {
  return (
    <div>
      <Footer style={{ textAlign: 'center' }}> © Jewelcocktail </Footer>
    </div>
  );
}

export default CustomFooter;
