import React from 'react';
import { Layout, Badge, Dropdown } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import HeaderStyled from './HeaderStyled';
import Avatar from 'antd/lib/avatar/avatar';
import useAuth from 'src/context/auth';

const { Header } = Layout;

function CustomHeader({ collapsed, toggle }) {
  const { logout } = useAuth();
  const items = [
    {
      key: 'Logout',
      label: <span onClick={logout}>Log out</span>,
    },
  ];
  return (
    <HeaderStyled>
      <Header className="site-layout-background">
        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
          className: 'trigger',
          onClick: toggle,
        })}
        <div className="float-end d-flex mr-2">
          <div className="notifications-header">
            <Badge dot>
              <NotificationOutlined />
            </Badge>
          </div>
          <div className="name-header mx-3">
            <Dropdown menu={{ items }}>
              <Avatar icon={<UserOutlined />} />
            </Dropdown>
            <span className="name">Jewel</span>
          </div>
        </div>
      </Header>
    </HeaderStyled>
  );
}

export default CustomHeader;
