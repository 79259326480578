import React, { useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { Form, Input, Button, Select, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAsync } from 'react-use';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import Loading from 'src/components/Loading';
import defaultHOCs from 'src/hocs/defaultHOCs';

const defaultValue = {
  name: '',
  user: 0,
  intervalDate: [],
  status: 'enabled',
};

function EditUser(props) {
  const { id } = useParams();
  const navigate = useNavigate();

  const [initialLoad, setInitialLoad] = useState(false);

  // const { isLoading, data = defaultValue } = useDiscount(id);

  const data = useAsync(async () => {
    if (!id) return defaultValue;
    const result = await axios.get(`/v1/users/${id}`);
    return result.data;
  }, [id]);

  const onFinish = async (values) => {
    try {
      setInitialLoad(true);
      console.log({ values });
      const { id, ...rest } = values;
      if (id) {
        await axios.patch(`/v1/users/${id}`, rest);
      }
      navigate(-1);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error?.response?.data?.message || error?.message,
        duration: 8,
      });
    } finally {
      setInitialLoad(false);
    }
  };
  return (
    <>
      {data?.loading ? (
        <Loading text={'Getting user from DB! Hold on gee...'} />
      ) : (
        <Form
          name="user_details_form"
          className="login-form"
          initialValues={data.value}
          onFinish={onFinish}
          layout="vertical"
          size="large"
          style={{ clear: 'both' }}
        >
          <Form.Item name="id">
            <Input type="hidden" />
          </Form.Item>
          <Form.Item
            name="name"
            label="Full Name"
            rules={[
              {
                required: true,
                message: 'Please input full name!',
              },
            ]}
          >
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="John Doe" />
          </Form.Item>
          <Form.Item
            label="Password"
            rules={[
              {
                required: true,
                message: 'Please input your Password!',
              },
            ]}
            name="password"
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: 'Please input your email!',
              },
            ]}
          >
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
          </Form.Item>

          {/* <Form.Item
            label="Role"
            name="role"
            rules={[
              {
                required: false,
                message: 'Pick a role!',
              },
            ]}
          >
            <Select>
              <Select.Option value="member">Member</Select.Option>
              <Select.Option value="staff">Staff</Select.Option>
              <Select.Option value="admin">Admin</Select.Option>
            </Select>
          </Form.Item> */}

          <Form.Item>
            <Button type="primary" loading={initialLoad} htmlType="submit" className="mr-2" disabled={initialLoad}>
              Save
            </Button>
            <Button type="info" className="login-form-button">
              <Link to="/users">Back</Link>
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
}

export default defaultHOCs(EditUser);
