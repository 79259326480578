import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Login from 'src/components/Auth/Login';
import Dashboard from 'src/components/Dashboard';
import ListQrcodes from 'src/components/Qrcode/List';
import AddQrcodes from 'src/components/Qrcode/Add';
import EditQrcodes from 'src/components/Qrcode/Edit';
import EditDiscount from 'src/components/Discount/Edit';
import EditUser from 'src/components/Users/Edit';
import ListDiscount from 'src/components/Discount/List';
import UsersList from 'src/components/Users/List';
import { RequireAuth } from './hocs';

const AppRoutes = () => (
  <Routes>
    <Route
      exact
      path="/dashboard"
      element={
        <RequireAuth>
          <Dashboard />
        </RequireAuth>
      }
    />
    <Route
      exact
      path="/qrcode/list"
      element={
        <RequireAuth>
          <ListQrcodes />
        </RequireAuth>
      }
    />
    <Route
      exact
      path="/qrcode/generation"
      element={
        <RequireAuth>
          <AddQrcodes />
        </RequireAuth>
      }
    />
    <Route
      exact
      path="/qrcode/edit/:id"
      element={
        <RequireAuth>
          <EditQrcodes />
        </RequireAuth>
      }
    />
    <Route
      exact
      path="/users"
      element={
        <RequireAuth>
          <UsersList />
        </RequireAuth>
      }
    />

    <Route
      exact
      path="/users/edit/:id"
      element={
        <RequireAuth>
          <EditUser />
        </RequireAuth>
      }
    />

    <Route
      exact
      path="/discount/list"
      element={
        <RequireAuth>
          <ListDiscount />
        </RequireAuth>
      }
    />
    <Route
      exact
      path="/discount/add"
      element={
        <RequireAuth>
          <EditDiscount />
        </RequireAuth>
      }
    />
    <Route
      exact
      path="/discount/edit/:id"
      element={
        <RequireAuth>
          <EditDiscount />
        </RequireAuth>
      }
    />
    <Route exact path="/" element={<Login />} />
  </Routes>
);

export default AppRoutes;
