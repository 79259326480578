import React from 'react';
import { get } from 'lodash';
import axios from 'axios';
import checkAdminAuth from 'src/utils/AdminAuth';

const AuthContext = React.createContext();

function useAuth() {
  const [isAuth, setAuthed] = React.useState(checkAdminAuth());

  return {
    isAuth,
    login(data) {
      return axios.post('/v1/auth/login', data).then((res) => {
        localStorage.setItem('token_access', get(res.data, 'tokens.access.token'));
        setAuthed(true);
        return res;
      });
    },
    logout() {
      localStorage.removeItem('token_access');
      setAuthed(false);
    },
  };
}

export function AuthProvider({ children }) {
  const auth = useAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export default function AuthConsumer() {
  return React.useContext(AuthContext);
}
