import React, { useState } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import { Layout } from 'antd';
import './default.css';

function DefaultLayoutHOC(Component) {
  return function DefaultLayoutCustomHoc(props) {
    const [collapsed, setCollapsed] = useState(true);

    const handleSetCollapsed = () => {
      setCollapsed(!collapsed);
    };
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Sidebar collapsed={collapsed} loggedInUserId={1} />
        <Layout className="site-layout">
          <Header collapsed={collapsed} toggle={handleSetCollapsed} />
          <div className="container">
            <Component {...props} />
          </div>
          <Footer />
        </Layout>
      </Layout>
    );
  };
}

export default DefaultLayoutHOC;
