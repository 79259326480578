import React, { useState } from 'react';
import axios from 'axios';
import { pick } from 'lodash';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Input, Layout } from 'antd';
import defaultHOCs from 'src/hocs/defaultHOCs';
import Loading from 'src/components/Loading';
import QrcodeTable from 'src/components/Qrcode/Table';
import { useListQrcodes } from 'src/queries/qrcodes';
const { Content } = Layout;

const { Search } = Input;

function QrcodeList() {
  const { pathname, state: params } = useLocation();
  const [term, setTerm] = useState(params?.q || '');
  const navigate = useNavigate();
  const rest = pick(params, ['limit', 'page', 'sortBy', 'createdAt', 'q']);
  const { isLoading, data } = useListQrcodes(rest);

  const onChangeTable = async (pagination, filters, sorter) => {
    const options = { limit: pagination.pageSize, page: pagination.current, sortBy: undefined, sorter: undefined };
    let replace = false;
    if (sorter.order) {
      const sort = sorter.order === 'descend' ? 'desc' : 'asc';
      options.sortBy = `${sorter.field}:${sort}`;
      options.sorter = { field: sorter.field, order: sorter.order };
      replace = true;
    }
    navigate(pathname, { replace, state: { ...params, ...options } });
  };

  const onDeleteQrcode = async (id) => {
    await axios.delete(`/v1/qrcodes/${id}`);
    navigate(pathname, { replace: true, state: { ...params } });
    // window.location.reload();
  };

  const onSearch = async (q) => {
    const val = q.trim();
    let state = {};
    if (val) {
      setTerm(val);
      state = { ...params, q: val };
    }
    navigate(pathname, { replace: true, state });
  };

  const onChange = (e) => {
    const { value } = e.target;
    setTerm(value);
  };

  const { results, ...options } = data || {};

  return (
    <>
      <div className="d-flex justify-content-end mb-3">
        <Link to="/qrcode/generation" className="btn btn-primary cursor-pointer">
          Generation Qrcodes
        </Link>
      </div>
      <div className="d-flex justify-content-center">
        {isLoading ? (
          <Loading text={'Getting qrcode from DB! Hold on gee...'} />
        ) : (
          <Content>
            <div>
              <Search
                placeholder="input search text"
                allowClear
                defaultValue={term}
                onChange={onChange}
                onSearch={onSearch}
              />
            </div>
            <br />
            <QrcodeTable
              data={results}
              options={{ ...options, sorter: params?.sorter }}
              onChangeTable={onChangeTable}
              onDeleteQrcode={onDeleteQrcode}
            />
          </Content>
        )}
      </div>
    </>
  );
}

export default defaultHOCs(QrcodeList);
