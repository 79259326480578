import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from 'src/redux/init';
import App from 'src/app';
import * as serviceWorker from './serviceWorker';

// import 'antd/dist/antd.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

const client = new QueryClient();
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <QueryClientProvider client={client}>
      <App />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
