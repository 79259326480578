import axios from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';

export const useListQrcodes = (params) =>
  useQuery({
    queryKey: ['listQrcodes', params],
    queryFn: async () => {
      const res = await axios.get('/v1/qrcodes', { params });
      return res.data;
    },
  });

export const useQrcode = (qrcodeId) =>
  useQuery({
    queryKey: ['getQrcode', qrcodeId],
    queryFn: async () => {
      const res = await axios.get(`/v1/qrcodes/${qrcodeId}`);
      return res.data;
    },
  });

export const useUpdateQrcode = (qrcodeId, params) =>
  useMutation({
    queryKey: ['getQrcode', qrcodeId, params],
    queryFn: async () => {
      const res = axios.patch(`/v1/qrcodes/${qrcodeId}`, params);
      return res.data;
    },
  });

export const useDeleteMedia = (qrcodeId, ids) =>
  useMutation({
    queryKey: ['deleteMedia', qrcodeId, ids],
    queryFn: async () => {
      const res = await axios.post(`/v1/qrcodes/delete-media/${qrcodeId}`, { ids: ids });
      return res.data;
    },
  });

export const useInsertQrcodes = (params) =>
  useMutation({
    queryKey: ['insertQrcodes', params],
    queryFn: async () => {
      const res = await axios.post('/v1/qrcodes/insert-many', params);
      return res.data;
    },
  });

export const useDeleteQrcode = (qrcodeId) =>
  useMutation({
    queryKey: ['deleteQrcode', qrcodeId],
    queryFn: async () => {
      const res = await axios.delete(`/v1/qrcodes/${qrcodeId}`);
      return res.data;
    },
  });
