import React, { useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { Form, Input, InputNumber, Button, Select, DatePicker, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useAsync } from 'react-use';

import Loading from 'src/components/Loading';
import defaultHOCs from 'src/hocs/defaultHOCs';
// import { useDiscount } from 'src/queries/discounts';

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = 'DD-MM-YYYY';
const defaultValue = {
  name: '',
  discount: 0,
  intervalDate: [],
  status: 'enabled',
};
const mappingFormData = (data) => {
  const { intervalDate, ...rest } = data || {};
  return {
    ...rest,
    ...(intervalDate?.length && { intervalDate: [dayjs(intervalDate[0]), dayjs(intervalDate[1])] }),
  };
};

function EditDiscount(props) {
  const { id } = useParams();
  const navigate = useNavigate();

  const [initialLoad, setInitialLoad] = useState(false);

  // const { isLoading, data = defaultValue } = useDiscount(id);

  const data = useAsync(async () => {
    if (!id) return defaultValue;
    const result = await axios.get(`/v1/discounts/${id}`);
    return result.data;
  }, [id]);

  const onFinish = async (values) => {
    try {
      setInitialLoad(true);
      console.log({ values });
      const { id, ...rest } = values;
      if (id) {
        await axios.patch(`/v1/discounts/${id}`, rest);
      } else {
        await axios.post('/v1/discounts/add', rest);
      }
      navigate(-1);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error?.response?.data?.message || error?.message,
        duration: 8,
      });
    } finally {
      setInitialLoad(false);
    }
  };
  return (
    <>
      {data?.loading ? (
        <Loading text={'Getting discount from DB! Hold on gee...'} />
      ) : (
        <Form
          name="discount_edit_form"
          className="discount-edit-form"
          onFinish={onFinish}
          layout="vertical"
          size="middle"
          style={{ clear: 'both' }}
          initialValues={mappingFormData(data.value)}
        >
          <Form.Item name="id">
            <Input type="hidden" />
          </Form.Item>

          <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Name required!' }]}>
            <Input placeholder="Name pormo code" />
          </Form.Item>

          <Form.Item label="Discount" name="discount" rules={[{ required: true, message: 'Discount input!' }]}>
            <InputNumber
              style={{ width: '100%' }}
              min={0}
              max={100}
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace('%', '')}
            />
          </Form.Item>
          <Form.Item name="typeProduct" label="Select Type Product" rules={[{ type: 'array' }]}>
            <Select mode="multiple" placeholder="Please select product" style={{ width: 265 }}>
              <Option value="talisman">Talisman</Option>
              <Option value="jewerly">Jewerly</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Interval avilable promo code" name="intervalDate">
            <RangePicker format={dateFormat} minDate={dayjs()} />
          </Form.Item>

          <Form.Item name="status" label="Status">
            <Select style={{ width: 150 }}>
              <Option value="enabled">Enabled</Option>
              <Option value="disabled">Disabled</Option>
              <Option value="deleted">Deleted</Option>
            </Select>
          </Form.Item>
          <Button type="primary" loading={initialLoad} htmlType="submit" size="middle" disabled={initialLoad}>
            Save
          </Button>
          <Button type="info" className="discount-form-button" size="middle">
            <Link to="/discount/list">Back</Link>
          </Button>
        </Form>
      )}
    </>
  );
}

export default defaultHOCs(EditDiscount);
