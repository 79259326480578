import axios from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';

export const useUsersList = (params) =>
  useQuery({
    queryKey: ['listUsers', params],
    queryFn: async () => {
      const res = await axios.get('/v1/users/', { params });
      return res.data;
    },
  });

export const useUser = (userId) =>
  useQuery({
    queryKey: ['getUser', userId],
    queryFn: async () => {
      const res = await axios.get(`/v1/users/${userId}`);
      return res.data;
    },
  });

export const useUpdateUser = (userId, params) =>
  useMutation({
    queryKey: ['getUser', userId, params],
    queryFn: async () => {
      const res = axios.patch(`/v1/users/${userId}`, params);
      return res.data;
    },
  });

export const useDeleteUser = (userId) =>
  useMutation({
    queryKey: ['deleteUser', userId],
    queryFn: async () => {
      const res = await axios.delete(`/v1/users/${userId}`);
      return res.data;
    },
  });
