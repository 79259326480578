import axios from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';

export const useListDiscounts = (params) =>
  useQuery({
    queryKey: ['listDiscounts', params],
    queryFn: async () => {
      const res = await axios.get('/v1/discounts', { params });
      return res.data;
    },
  });

export const useDiscount = (id) =>
  useQuery({
    queryKey: ['getDiscount', id],
    queryFn: async () => {
      const res = await axios.get(`/v1/discounts/${id}`);
      return res.data;
    },
    enabled: !!id,
  });

export const useUpdateDiscount = (id, params) =>
  useMutation({
    queryKey: ['getDiscount', id, params],
    queryFn: async () => {
      const res = axios.patch(`/v1/discounts/${id}`, params);
      return res.data;
    },
  });

export const useDeleteDiscount = (id) =>
  useMutation({
    queryKey: ['deleteDiscount', id],
    queryFn: async () => {
      const res = await axios.delete(`/v1/discounts/${id}`);
      return res.data;
    },
  });
